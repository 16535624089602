import React, { useState, useContext, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import gsap, { Power3 } from 'gsap'
import { mapEdgesToNodes } from '../lib/helpers'
import localize from '../containers/localize'
import SEO from '../components/seo'
import PageHero from '../components/PageHero'
import Values from '../components/about/Values'
import RandomFacts from '../components/about/RandomFacts'
import Services from '../components/about/Services'
import { GlobalDispatchContext, GlobalStateContext } from '../containers/GlobalContextProvider'
import NextSectionIndicator from '../components/NextSectionIndicator'
import NextPageTrigger from '../components/NextPageTrigger'
import WithPrevPageTrigger from '../containers/WithPrevPageTrigger'
import PrevPageTrigger from '../components/PrevPageTrigger'
import '../lib/prevent-pull-refresh'

export const query = graphql`
  query AboutPageQuery {
    facts: allSanityFact {
      edges {
        node {
          _type
          id
          _rawText
          number
          text {
            en {
              _key
            }
            sl {
              _key
            }
          }
        }
      }
    }
    services: allSanityService(sort: { fields: [orderNumber], order: ASC }) {
      edges {
        node {
          _type
          id
          title {
            _type
            sl
            en
          }
          _rawContent
          relatedProject {
            _id
            title {
              _type
              sl
              en
            }
            slug {
              _type
              sl {
                current
              }
              en {
                current
              }
            }
          }
          image {
            alt {
              _type
              sl
              en
            }
            asset {
              _id
              fixed(width: 800) {
                ...GatsbySanityImageFixed
              }
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }
    page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title {
        _type
        sl
        en
      }
      description {
        _type
        sl
        en
      }
      heroImage {
        asset {
          _id
          fluid(maxWidth: 2800) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      shareImage {
        _type
        sl {
          asset {
            _id
          }
        }
        en {
          asset {
            _id
          }
        }
      }
      heroTitle {
        _type
        sl
        en
      }
      heroTekst {
        _type
        sl
        en
      }
    }
    projects: sanityPage(_id: { regex: "/(drafts.|)projects/" }) {
      id
      heroImage {
        asset {
          _id
          fluid(maxWidth: 2800) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
  }
`

const AboutPage = props => {
  const { data, errors } = props

  if (errors) return <GraphQLErrorList errors={errors} />

  const locale = props.pageContext.locale
  const page = data && data.page
  const services = (data || {}).services ? mapEdgesToNodes(data.services) : []
  const facts = (data || {}).facts
    ? mapEdgesToNodes(data.facts).filter(fact => fact.text[locale] !== null)
    : []

  const [pageReady, setPageReady] = useState(false)
  const [heroReady, setHeroReady] = useState(false)
  const [heroAnimated, setHeroAnimated] = useState(false)
  const [fontsReady, setFontsReady] = useState(false)
  const [showNextIndicator, setShowNextIndicator] = useState(false)
  const [isNextPageTriggerShown, showNextPageTrigger] = useState(false)
  const [isPrevPageTriggerEnabled, enablePrevPageTrigger] = useState(false)

  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const taglines = {
    hero: {
      sl: 'O nas',
      en: 'About us'
    },
    services: {
      sl: 'Storitve',
      en: 'Services'
    },
    values: {
      sl: 'Vrednote',
      en: 'Values'
    },
    facts: {
      sl: 'Dejstva',
      en: 'Facts'
    }
  }

  const { fontsLoaded } = context
  useEffect(() => {
    if (fontsLoaded) setFontsReady(true)
    if (fontsReady && heroReady) onPageReady()
  }, [fontsLoaded, fontsReady, heroReady])

  const sectionOffsets = useRef()
  useEffect(() => {
    sectionOffsets.current = []
    if (context.servicesMenuSelected) {
      dispatch({
        type: 'SET_PAGE_CONTEXT_DATA',
        data: { ...props.pageContext, tagline: taglines.services[locale], layout: 'normal' }
      })
      dispatch({
        type: 'SET_HEADER_VARIATION',
        variation: 'dark'
      })
    } else {
      dispatch({
        type: 'SET_PAGE_CONTEXT_DATA',
        data: { ...props.pageContext, layout: 'fullPage' }
      })
      dispatch({
        type: 'SET_HEADER_VARIATION',
        variation: 'light'
      })
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll)

      document.querySelectorAll('section').forEach(section => {
        const id = section.id
        if (taglines[id]) {
          if (!sectionOffsets.current.find(section => section.id === id)) {
            sectionOffsets.current.push({
              offset: section.offsetTop,
              height: section.offsetHeight,
              id: section.id
            })
          }
        }
      })
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  let currentSection = ''
  const triggerOffset = 50
  let nextTriggerSectionShown = false
  function onScroll() {
    const scrollY = window.scrollY

    if (scrollY > document.body.scrollHeight - window.innerHeight * 1.5) {
      if (!nextTriggerSectionShown) {
        showNextPageTrigger(true)
        nextTriggerSectionShown = true
      }
    } else {
      if (nextTriggerSectionShown) {
        showNextPageTrigger(false)
        nextTriggerSectionShown = false
      }
    }

    for (let i = 0; i < sectionOffsets.current.length; i++) {
      const { offset, id, height } = sectionOffsets.current[i]
      offset -= triggerOffset
      if (scrollY > offset && scrollY < offset + height) {
        if (currentSection === id || !taglines[id]) continue
        const variation = id === 'hero' ? 'light' : 'dark'
        const tagline = taglines[id][locale]
        dispatch({
          type: 'SET_TAGLINE',
          tagline
        })
        dispatch({
          type: 'SET_HEADER_VARIATION',
          variation
        })
        currentSection = id
      }
    }
  }

  function animateScrollTo(sectionId) {
    const targetSection = sectionOffsets.current.find(section => section.id === sectionId)
    if (!targetSection && sectionId !== 'bottom') return
    let targetScrollY
    if (sectionId === 'bottom') {
      targetScrollY = document.body.scrollHeight
    } else {
      targetScrollY = targetSection.offset
    }
    const animatable = { scrollY: window.scrollY }
    gsap.to(animatable, {
      scrollY: targetScrollY,
      duration: 0.8,
      ease: Power3.easeOut,
      onUpdate: () => {
        window.scrollTo(0, animatable.scrollY)
      }
    })
  }

  function onPageReady() {
    if (context.servicesMenuSelected) {
      setTimeout(() => {
        window.scrollTo(0, window.innerHeight)
        dispatch({ type: 'SERVICES_MENU_SELECTED', selected: false })
        dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageEntering' })
        setPageReady(true)
      }, 100)
    } else {
      dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageEntering' })
      setPageReady(true)
    }
  }

  function onHeroAnimated() {
    dispatch({ type: 'SET_PAGE_LAYOUT', layout: 'normal' })
    setHeroAnimated(true)
    setShowNextIndicator(true)
  }

  return (
    <>
      <SEO description={page.description} title={page.title} shareImage={page.shareImage} />

      <PrevPageTrigger
        enable={isPrevPageTriggerEnabled}
        show={heroAnimated}
        link="/"
        title={locale === 'sl' ? 'Domov' : 'Home'}
      ></PrevPageTrigger>

      <WithPrevPageTrigger onShift={isShifted => enablePrevPageTrigger(isShifted)}>
        <PageHero
          title={page.heroTitle}
          text={page.heroTekst}
          image={page.heroImage}
          onImageLoaded={() => setHeroReady(true)}
          onAnimated={onHeroAnimated}
          startAnimations={pageReady}
        >
          <NextSectionIndicator
            label={taglines.services[locale]}
            variation="white"
            onClick={() => animateScrollTo('services')}
            show={showNextIndicator}
          />
        </PageHero>
        <Services locale={locale} services={services} />
        <Values locale={locale} loadAnimation />
        <RandomFacts facts={facts} locale={locale} loadAnimation />

        <NextPageTrigger
          shown={isNextPageTriggerShown}
          enabled={heroAnimated}
          zIndex={3}
          title={locale === 'sl' ? 'Projekti' : 'Projects'}
          link={locale === 'sl' ? '/projekti' : '/en/projects'}
        ></NextPageTrigger>
      </WithPrevPageTrigger>
    </>
  )
}

export default localize(AboutPage)
