import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import Img from 'gatsby-image'
import styles from './Service.module.css'
import BlockContent from '../block-content'
import animations from '../fullPage/animations'
import { debouncedResize, clearDebouncedResize } from '../../lib/helpers'
import { GlobalDispatchContext } from '../../containers/GlobalContextProvider'
import LinkTransition from '../navigation/LinkTransition'

export default class Service extends Component {
  static contextType = GlobalDispatchContext
  static propTypes = {
    layout: PropTypes.oneOf(['imageLeft', 'imageRight']),
    isActive: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    _rawContent: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    relatedProject: PropTypes.object,
    animationDirection: PropTypes.oneOf(['right', 'left']),
    onAnimationStart: PropTypes.func,
    onAnimationEnd: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.animationName = 'fadeLeftRight'
    this.wrapRef = React.createRef()
    this.mobileBreakpoint = 900
    this.onResize = this.onResize.bind(this)

    this.state = {
      isShown: this.props.isActive ? true : false,
      layout: 'desktop',
      imageHeight: 'auto'
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const layout = window.innerWidth < 900 ? 'mobile' : 'desktop'
      this.setState({ layout, imageHeight: window.innerHeight * 0.3 })
      this.resizeReference = debouncedResize(this.onResize)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive)
      this.startAnimating(this.props.animationDirection, this.props.isActive)
  }

  componentWillUnmount() {
    if (this.resizeReference) clearDebouncedResize(this.resizeReference)
  }

  onResize() {
    const layout = window.innerWidth < 900 ? 'mobile' : 'desktop'
    this.setState({ layout, imageHeight: window.innerHeight * 0.3 })
  }

  async startAnimating(direction, isEntering) {
    const { leave, enter, settings } = animations[this.animationName]
    const startState = isEntering ? enter.start[direction] : leave.start
    const endState = isEntering ? enter.end : leave.end[direction]

    if (isEntering) this.setState({ isShown: true })
    this.onAnimationStart()
    await this.animate(startState, endState, settings.duration, settings.ease)
    this.onAnimationEnd()
    if (!isEntering) this.setState({ isShown: false })
  }

  onAnimationStart() {
    if (this.props.onAnimationStart) this.props.onAnimationStart()
  }

  onAnimationEnd() {
    if (this.props.onAnimationEnd) this.props.onAnimationEnd()
  }

  animate(startState, endState, duration, ease) {
    return new Promise(resolve => {
      gsap.fromTo(this.wrapRef.current, duration, startState, {
        ...endState,
        ease,
        onComplete: () => resolve()
      })
    })
  }

  contentTemplate(projectLink) {
    const { title, _rawContent, id, layout } = this.props
    const projectText =
      this.props.locale === 'en' ? 'View related project' : 'Oglej si referenčni projekt'

    return (
      <div className={styles.content} key={id + '-content'}>
        <h3 className={styles.title}>{title}</h3>
        {layout === 'mobile' ? this.imageTemplate() : ''}
        <div className={styles.text}>
          <BlockContent blocks={_rawContent}></BlockContent>
        </div>
        <div className={styles.projectLink}>
          <LinkTransition
            to={projectLink}
            label={
              <span>
                <span>{projectText}</span>
                <span className={`${styles.triangle} ${styles.triangleRight}`} />
              </span>
            }
            exitTrigger={() => this.context({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })}
          ></LinkTransition>
        </div>
      </div>
    )
  }

  imageTemplate(projectLink) {
    const { image, id } = this.props
    let imageAttrs = { width: '100%' }
    let wrapAttrs = { width: '100%' }

    if (this.state.layout === 'mobile') {
      imageAttrs = { ...imageAttrs, objectFit: 'cover', objectPosition: 'center center' }
      wrapAttrs = { ...wrapAttrs, height: this.state.imageHeight }
    } else {
      imageAttrs = { ...imageAttrs, objectFit: 'contain', objectPosition: 'top center' }
    }

    return (
      <div className={styles.image} key={id + '-image'}>
        <LinkTransition
          to={projectLink}
          label=""
          exitTrigger={() => this.context({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })}
        ></LinkTransition>
        <Img
          fixed={image.asset.fixed}
          alt={image.alt}
          style={wrapAttrs}
          imgStyle={imageAttrs}
        ></Img>
      </div>
    )
  }

  render() {
    const projectLink =
      this.props.locale === 'en'
        ? `/en/project/${this.props.relatedProject.slug.current}`
        : `/projekt/${this.props.relatedProject.slug.current}`
    const { layout } = this.props
    return (
      <div
        className={`${styles.root} ${this.state.isShown ? styles.active : ''} ${styles[layout]}`}
        ref={this.wrapRef}
      >
        <div className={styles.desktopLayout}>
          {layout === 'imageLeft'
            ? [this.imageTemplate(projectLink), this.contentTemplate(projectLink)]
            : [this.contentTemplate(projectLink), this.imageTemplate(projectLink)]}
        </div>
        <div className={styles.mobileLayout}>
          {[this.imageTemplate(projectLink), this.contentTemplate(projectLink)]}
        </div>
      </div>
    )
  }
}
