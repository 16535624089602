import React from 'react'
import PropTypes from 'prop-types'
import Container from '../Container'
import styles from './Values.module.css'
import LottieAnimation from '../../animations/LottieAnimation'
import NextSectionIndicator from '../NextSectionIndicator'
function Values ({ locale, loadAnimation, nextSection, onNextTrigger }) {
  const values = [
    { sl: 'Odgovornost', en: 'Responsibility' },
    { sl: 'Zaupanje', en: 'Trust' },
    { sl: 'Povezanost', en: 'Connectedness ' }
  ]
  const text = {
    sl:
      '<p><strong>Vrednote nas spominjajo na to, kdo smo in kaj hočemo postati.</strong> So naše vodilo pri vsem, kar počnemo, in temelji, na katerih gradimo odnose z naročniki in sodelovanje znotraj ekipe.</p> <p>Pri vseh procesih, od snovanja do izvedbe, vedno spoštujemo in upoštevamo vse deležnike – zaposlene in njihove družine, dobavitelje, izvajalce, blagovne znamke, potrošnike in naročnike.</p>',
    en:
      '<p><strong>Our values remind us of who we are and what we want to become. </strong>They are our guideline at everything we do and our foundations on which we build client relations and team bonds.</p><p> When working on a project, we are careful to respect everyone’s needs in all of the processes. We strive to create a goodnment for our team and their families, for suppliers, contractors, brands, consumers and clients alike.</p> '
  }

  return (
    <section className={styles.root} id='values'>
      <div className={styles.inner}>
        <Container>
          <div className={`${styles.valueList} ${styles.showDesktop}`}>
            {values.map((value, index) => (
              <div className={styles.value} key={index}>
                <div className={styles.line} />
                <div className={styles.valueName}>{value[locale]}</div>
              </div>
            ))}
          </div>
          <div className={styles.content}>
            <div className={styles.girl}>
              <LottieAnimation
                name='skyping'
                load={loadAnimation}
                autoplay
                loop
                showPlaceholder
                renderer='svg'
              />
            </div>
            <div className={`${styles.valueList} ${styles.showMobile}`}>
              {values.map((value, index) => (
                <div className={styles.value} key={index}>
                  <div className={styles.valueName}>{value[locale]}</div>
                  <div className={styles.line} />
                </div>
              ))}
            </div>
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: text[locale] }} />
          </div>
        </Container>
      </div>
      {nextSection && (
        <div className={styles.nextSection}>
          <NextSectionIndicator label={nextSection} variation='dark' onClick={onNextTrigger} show />
        </div>
      )}
    </section>
  )
}

Values.propTypes = {
  locale: PropTypes.string.isRequired,
  loadAnimation: PropTypes.bool.isRequired,
  nextSection: PropTypes.string,
  onNextTrigger: PropTypes.func
}

export default Values
