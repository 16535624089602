import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gsap, { Power2 } from 'gsap'
import styles from './ServicesTabs.module.css'

class ServicesTabs extends Component {
  static propTypes = {
    services: PropTypes.array.isRequired,
    onSelected: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    activeServiceIndex: PropTypes.number
  }

  constructor(props) {
    super(props)
    this.mobile = typeof window !== 'undefined' && window.innerWidth < 900
    this.tabsWrap = React.createRef()
    this.state = {
      selectedIndex: 0
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown.bind(this))
    this.changeActiveTab(this.props.activeServiceIndex)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeServiceIndex !== this.props.activeServiceIndex) {
      this.changeActiveTab(this.props.activeServiceIndex)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown.bind(this))
  }

  onKeyDown(e) {
    const left = 37
    const right = 39
    if (e.keyCode === left) {
      this.changeActiveTab(this.state.selectedIndex - 1)
    }
    if (e.keyCode === right) {
      this.changeActiveTab(this.state.selectedIndex + 1)
    }
  }

  tabSelected(index) {
    this.props.onSelected(index)
  }

  changeActiveTab(index) {
    // Scroll tab list so that selected tab is centered.
    if (!this.tabsWrap.current || this.props.disabled) return false
    if (index < 0 || index > this.props.services.length - 1) return false
    const list = this.tabsWrap.current
    const tab = list.children[index]
    const scrollTo = tab.offsetLeft - tab.offsetWidth / 2

    const animatable = { scrollX: this.tabsWrap.current.scrollLeft }
    gsap.to(animatable, {
      scrollX: scrollTo,
      duration: 0.6,
      ease: Power2.easeInOut,
      onUpdate: () => {
        list.scrollTo(animatable.scrollX, 0)
      }
    })

    this.setState({ selectedIndex: index })
  }

  render() {
    const { services } = this.props
    return (
      <div className={styles.tabs}>
        <div className={styles.tabsInner} ref={this.tabsWrap}>
          {services.map((service, index) => (
            <div
              className={`${styles.tab} ${service.isActive ? styles.active : ''}`}
              onClick={() => this.tabSelected(index)}
              key={index}
              role="button"
            >
              {service.title}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default ServicesTabs
