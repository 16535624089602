import { Power2 } from 'gsap'

const defaults = {
  directionalFadeLength: 60, // How many pixels to animate fadeUp/fadeDown animations
  duration: 0.6, // Default animation length for all animations
  ease: Power2.easeInOut
}

const fadeDownOut = {
  y: defaults.directionalFadeLength,
  opacity: 0
}

const fadeUpOut = {
  y: -defaults.directionalFadeLength,
  opacity: 0
}

const fadeLeftOut = {
  x: -defaults.directionalFadeLength,
  opacity: 0
}

const fadeRightOut = {
  x: defaults.directionalFadeLength,
  opacity: 0
}

const fadeIn = {
  y: 0,
  x: 0,
  opacity: 1
}

const fadeUpDown = {
  settings: {
    duration: defaults.duration,
    ease: defaults.ease
  },
  leave: {
    start: { y: 0, opacity: 1 },
    end: {
      up: {
        ...fadeDownOut
      },
      down: {
        ...fadeUpOut
      }
    }
  },
  enter: {
    start: {
      up: {
        ...fadeUpOut
      },
      down: {
        ...fadeDownOut
      }
    },
    end: { ...fadeIn }
  }
}

export const fadeLeftRight = {
  settings: {
    duration: defaults.duration,
    ease: defaults.ease
  },
  leave: {
    start: { x: 0, opacity: 1 },
    end: {
      left: {
        ...fadeLeftOut
      },
      right: {
        ...fadeRightOut
      }
    }
  },
  enter: {
    start: {
      left: {
        ...fadeRightOut
      },
      right: {
        ...fadeLeftOut
      }
    },
    end: { ...fadeIn }
  }
}

const fade = {
  settings: {
    duration: defaults.duration,
    ease: defaults.ease
  },
  leave: {
    start: { y: 0, opacity: 1 },
    end: {
      up: {
        opacity: 0
      },
      down: {
        opacity: 0
      }
    }
  },
  enter: {
    start: {
      up: {
        opacity: 0
      },
      down: {
        opacity: 0
      }
    },
    end: { ...fadeIn }
  }
}

export default {
  default: {
    ...fadeUpDown
  },
  fade,
  fadeUpDown,
  fadeLeftRight
}
