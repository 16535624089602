import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import Tabs from './ServicesTabs'
import Container from '../Container'
import Service from './Service'
import styles from './Services.module.css'
import NextSectionIndicator from '../NextSectionIndicator'

export default class Services extends Component {
  static propTypes = {
    services: PropTypes.array.isRequired,
    nextSection: PropTypes.string,
    locale: PropTypes.string,
    onNextTrigger: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.sectionRef = createRef()
    this.swipeable = createRef()
    this.fired = false
    this.firedHero = true
    this.state = {
      isAnimating: false,
      animationDirection: 'right',
      services: props.services.map((service, index) => ({
        ...service,
        isActive: index === 0 ? true : false
      }))
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const scrollOptions = {
        el: this.swipeable.current
      }
      import('virtual-scroll').then(module => {
        const Scroller = module.default
        this.virtualScroll = new Scroller(scrollOptions)
        this.virtualScroll.on(this.onSwipe, this)
      })
    }
  }

  onSwipe(e) {
    const { deltaX, deltaY, originalEvent } = e

    if (this.state.isAnimating) {
      originalEvent.preventDefault()
      return
    }

    if (originalEvent.type === 'touchmove') {
      const isHorizontalSwipe = Math.abs(deltaX) > Math.abs(deltaY)
      if (isHorizontalSwipe) {
        originalEvent.preventDefault()
        const swipeDirection = deltaX < 1 ? 'left' : 'right'
        swipeDirection === 'left' ? this.nextService() : this.prevService()
      }
    }
  }

  nextService() {
    const currentServiceIndex = this.state.services.findIndex(service => service.isActive)
    const nextIndex = currentServiceIndex + 1
    if (nextIndex > this.state.services.length - 1) return
    this.setState({ animationDirection: 'left' })
    this.setActive(nextIndex)
  }

  prevService() {
    const currentServiceIndex = this.state.services.findIndex(service => service.isActive)
    const prevIndex = currentServiceIndex - 1
    if (prevIndex < 0) return
    this.setState({ animationDirection: 'right' })
    this.setActive(prevIndex)
  }

  tabSelected(newServiceIndex) {
    const currentServiceIndex = this.state.services.findIndex(service => service.isActive)
    if (newServiceIndex > currentServiceIndex) this.setState({ animationDirection: 'left' })
    else if (newServiceIndex < currentServiceIndex) this.setState({ animationDirection: 'right' })
    else return

    this.setActive(newServiceIndex)
  }

  setActive(newIndex) {
    this.setState({
      services: this.state.services.map((service, index) => ({
        ...service,
        isActive: index === newIndex ? true : false
      }))
    })
  }

  render() {
    return (
      <>
        <section id="services" className={styles.root} ref={this.sectionRef}>
          <div className={styles.content}>
            <div>
              <div className={styles.tabs}>
                <Tabs
                  services={this.state.services}
                  onSelected={this.tabSelected.bind(this)}
                  disabled={this.state.isAnimating}
                  activeServiceIndex={this.state.services.findIndex(service => service.isActive)}
                ></Tabs>
              </div>

              <Container noPadding>
                <div className={styles.slider} ref={this.swipeable}>
                  {this.state.services.map((service, index) => (
                    <Service
                      key={service.id}
                      locale={this.props.locale}
                      {...service}
                      animationDirection={this.state.animationDirection}
                      layout={index % 2 === 0 ? 'imageRight' : 'imageLeft'}
                      onAnimationStart={() => this.setState({ isAnimating: true })}
                      onAnimationEnd={() => this.setState({ isAnimating: false })}
                    ></Service>
                  ))}
                </div>
              </Container>
            </div>
          </div>
          {this.props.nextSection && (
            <div className={styles.nextSection}>
              <NextSectionIndicator
                label={this.props.nextSection}
                variation="dark"
                onClick={this.props.onNextTrigger}
                show
              />
            </div>
          )}
        </section>
      </>
    )
  }
}
