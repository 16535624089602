import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './RandomFacts.module.css'
import hashtag from '../../images/hashtag.svg'
import fwd from '../../images/fwd.svg'
import BlockContent from '../block-content'
import LottieAnimation from '../../animations/LottieAnimation'
import NextSectionIndicator from '../NextSectionIndicator'

function RandomFacts({ facts, locale, loadAnimation, nextSection, onNextTrigger }) {
  const [randomFact, setRandomFact] = useState(getRandomFact(true))

  const title = { sl: '101 naključno in neuporabno dejstvo o nas', en: '101 random facts about us' }
  const nextFact = { sl: 'Naslednje dejstvo', en: 'Next fact' }

  function getRandomFact(init = false) {
    const newRandomFact = facts[Math.floor(Math.random() * facts.length)]
    if (!init && newRandomFact == randomFact) return getRandomFact()
    return newRandomFact
  }

  return (
    <section id="facts" className={`${styles.root}`}>
      <div className={styles.inner}>
        <div className={styles.content}>
          <h3 className={styles.title}>{title[locale]}</h3>
          <div className={styles.fact}>
            <div className={styles.number}>
              <span>
                <img src={hashtag} alt="Number" />
              </span>
              <span>{randomFact.number}</span>
            </div>
            <div className={styles.text}>
              <BlockContent blocks={randomFact._rawText} />
            </div>
          </div>
          <div className={styles.nextFact}>
            <button
              className={`${styles.nextButton} bordered`}
              onClick={() => setRandomFact(getRandomFact())}
              id="btn-next-fact"
            >
              {nextFact[locale]} <img src={fwd} alt="Next fact" />
            </button>
          </div>
          <div className={styles.barbecue}>
            <LottieAnimation
              name="barbecue"
              load={loadAnimation}
              autoplay
              loop
              showPlaceholder
              renderer="svg"
            />
          </div>
          <div className={styles.clear} />
        </div>
      </div>
      {nextSection && (
        <div className={styles.nextSection}>
          <NextSectionIndicator label={nextSection} variation="dark" onClick={onNextTrigger} show />
        </div>
      )}
    </section>
  )
}

RandomFacts.propTypes = {
  facts: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  loadAnimation: PropTypes.bool.isRequired
}

export default RandomFacts
