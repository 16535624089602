import React, { Component } from 'react'
import PropTypes from 'prop-types'
import lottie from 'lottie-web'
import styles from './LottieAnimation.module.css'

export default class LottieAnimation extends Component {
  static propTypes = {
    name: PropTypes.oneOf([
      'skyping',
      'barbecue',
      'construction',
      'dog',
      'male-walking',
      'tree',
      'ufo',
      'ufo-centered'
    ]).isRequired,
    renderer: PropTypes.oneOf(['svg', 'canvas']).isRequired,
    load: PropTypes.bool,
    autoplay: PropTypes.bool,
    loop: PropTypes.bool,
    play: PropTypes.bool,
    pause: PropTypes.bool,
    stop: PropTypes.bool,
    goToAndStop: PropTypes.bool,
    complete: PropTypes.bool,
    showPlaceholder: PropTypes.bool,
    onLoopComplete: PropTypes.func,
    onLoad: PropTypes.func,
    onComplete: PropTypes.func,
    playbackSpeed: PropTypes.number,
    preserveAspectRatio: PropTypes.string,
    staticFallback: PropTypes.bool,
    // Performance props
    numberOfLogicalProcessors: PropTypes.number,
    deviceMemory: PropTypes.number,
    effectiveConnectionType: PropTypes.string
  }

  static defaultProps = {
    loop: true,
    autoplay: true,
    staticFallback: false // Static fallback provides static .svg image and is used on low-end devices
  }

  constructor(props) {
    super(props)
    this.animationHolder = React.createRef()
    this.lottieInstance = null
    this.state = {
      loading: false,
      loaded: false,
      playing: false,
      isStatic: false,
      staticSource: null
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.load !== this.props.load) {
      if (!this.state.loaded && !this.state.loading) this.load()
    } else if (this.state.isStatic) {
      return true
    } else if (prevProps.play !== this.props.play) {
      if (this.state.loaded) this.props.play ? this.play() : this.pause()
    } else if (prevProps.loop !== this.props.loop) {
      this.lottieInstance.loop = this.props.loop
    } else if (prevProps.complete !== this.props.complete) {
      this.props.complete ? this.complete() : this.restart()
    } else if (prevProps.playbackSpeed !== this.props.playbackSpeed) {
      this.setPlaybackSpeed(this.props.playbackSpeed)
    } else if (prevProps.pause !== this.props.pause) {
      if (this.state.loaded) this.props.pause ? this.pause() : this.play()
    } else if (prevProps.stop !== this.props.stop) {
      if (this.state.loaded) this.props.stop ? this.stop() : this.play()
    }
  }

  componentDidMount() {
    // If load prop is true on mount we load immidiately
    if (this.props.load) this.load()
  }

  stop() {
    this.lottieInstance.stop()
    this.setState({ playing: false })
  }

  restart() {
    this.lottieInstance.stop()
    this.lottieInstance.play()
    this.setState({ playing: true })
  }

  complete() {
    // Complete the animation, setting its progress to the last frame
    this.lottieInstance.goToAndStop(this.lottieInstance.getDuration(true), true)
    this.lottieInstance.loop = false
    this.setState({ playing: false })
  }

  pause() {
    this.lottieInstance.pause()
    this.setState({ playing: false })
  }

  play() {
    this.lottieInstance.play()
    this.setState({ playing: true })
  }

  setPlaybackSpeed(speed) {
    this.lottieInstance.setSpeed(speed)
  }

  onLoad() {
    this.setState({ loading: false, loaded: true })
    this.setEventListeners()
    if (this.props.onLoad) this.props.onLoad(this.props.name)
    if (this.props.playbackSpeed) this.setPlaybackSpeed(this.props.playbackSpeed)
  }

  onStaticLoad() {
    this.setState({ loading: false, loaded: true, isStatic: true })
    if (this.props.onLoad) this.props.onLoad(this.props.name)
  }

  onComplete() {
    // Animation finished
    if (this.props.onComplete) this.props.onComplete()
  }

  onLoopComplete() {
    if (this.props.onLoopComplete) this.props.onLoopComplete()
  }

  setEventListeners() {
    this.lottieInstance.addEventListener('loopComplete', () => {
      this.onLoopComplete()
    })
    this.lottieInstance.addEventListener('complete', () => {
      this.onComplete()
    })
  }

  isLowEndDevice() {
    if (!this.props.numberOfLogicalProcessors) return false // If we do not know, we can not assume that it is low end device
    return this.props.numberOfLogicalProcessors < 4 || this.props.deviceMemory < 2
  }

  isSlowConnection() {
    if (!this.props.effectiveConnectionType) return false
    return ['slow-2g', '2g'].includes(this.props.effectiveConnectionType)
  }

  load() {
    // We can not load animation if DOM has not been loaded or if we SSR
    if (this.animationHolder === null || typeof window === 'undefined') return
    this.setState({ loading: true })

    if (this.props.staticFallback && (this.isLowEndDevice() || this.isSlowConnection())) {
      return import(`./${this.props.name}/static.svg`).then(data => {
        this.setState({ staticSource: data.default })
        this.onStaticLoad()
      })
    }

    import(`./${this.props.name}/data.json`).then(data => {
      this.lottieInstance = lottie.loadAnimation({
        container: this.animationHolder.current,
        renderer: this.props.renderer,
        loop: this.props.loop,
        autoplay: false,
        animationData: data,
        rendererSettings: {
          progressiveLoad: true,
          preserveAspectRatio: this.props.preserveAspectRatio || 'xMidYMid meet'
        }
      })
      this.onLoad()
      if (this.props.autoplay) this.play()
    })
  }

  render() {
    return (
      <div ref={this.animationHolder} className={styles.root}>
        {this.state.isStatic && (
          <div className={styles.placeholder}>
            <img src={this.state.staticSource} alt={`${this.props.name}`} />
          </div>
        )}
        {this.props.showPlaceholder && !this.state.loaded && (
          <div className={styles.placeholder}>
            <img
              src={require(`./${this.props.name}/placeholder.svg`)}
              alt={`Animation ${this.props.name} placeholder`}
            />
          </div>
        )}
      </div>
    )
  }
}
